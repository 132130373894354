import { createApp } from 'vue'
//import Vue from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery'
import 'popper.js'
import 'bootstrap'
import './assets/app.scss'
import 'vue-css-donut-chart/dist/vcdonut.css';
import 'chart.js'
import 'vue-chartjs'
import VueApexCharts from "vue3-apexcharts";
//import Vue from 'vue';
//import Vuelidate from 'vuelidate';
//createApp(App).use(Vuelidate)
//import VueHtml2pdf from 'vue-html2pdf'
//Vue.use(VueHtml2pdf)
//import VueCookies from 'vue-cookies'

//createApp(App).use(Donut).mount('#app')
window.$ = window.jQuery = jQuery
createApp(App).use(router, VueApexCharts).mount('#app')
