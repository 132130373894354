<template>
  <div v-if="loG" style="padding: 0px; margin: 0px" class="">
    <nav class="navbar mobile-nav navbar-expand-lg navbar-dark bg-primary">
      <a class="navbar-brand" href="#">
        <span
          style=" border-radius: 100%"
          class="logomobile p-2"
        >
          <img
            src="./assets/logosite.png"
            width="30"
            height="30"
            class="d-inline-block align-top"
            alt=""
            style="object-fit: contain"
          />
        </span>
        Les Jeunes Solidaires
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <router-link class="nav-link routlink active" to="/">
              <span
                ><img
                  class="iconmenu"
                  src="./assets/png/011-dashboard-1.png"
                  width="25"
                  height="25"
                  alt=""
                  srcset=""
              /></span>
              Tableau de bord <span class="sr-only">(current)</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link
              class="nav-link routlink active"
              :to="`/Membre-liste/${0} `"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi iconmenu bi-person-lines-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
                />
              </svg>
              Membres <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link routlink active"
              :to="`/Organisation-liste/${0} `"
            >
              <img
                class="iconmenu"
                src="./assets/png/012-community-manager-1.png"
                width="25"
                height="25"
                alt=""
                srcset=""
              />
              Organisations <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link routlink active"
              :to="`/Evenement-liste/${0} `"
            >
              <img
                class="iconmenu"
                src="./assets/png/013-calendar-silhouette-1.png"
                width="25"
                height="25"
                alt=""
                srcset=""
              />
              Evènements <span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link routlink active"
              :to="`/Formation-liste/${0} `"
            >
              <img
                class="iconmenu"
                src="./assets/png/014-note.png"
                width="25"
                height="25"
                alt=""
                srcset=""
              />
              Formations <span class="sr-only">(current)</span>
            </router-link>
          </li>

          <li class="nav-item">
            <a
              class="nav-link active"
              @click="all_localite()"
              data-bs-toggle="modal"
              data-bs-target="#exampleModallocalite"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi iconmenu bi-geo-alt-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                />
              </svg>
              Localités
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link active"
              @click="all_bureau()"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalbureau"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi iconmenu bi-building"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 
                                  1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 
                                  .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                />
                <path
                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 
                                  2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                />
              </svg>
              Bureaux
            </a>
          </li>

          <li class="nav-item active">
            <a
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#modalAdmin"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi iconmenu bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
              Profil <span class="sr-only">(current)</span>
            </a>
          </li>

          <li v-if="user.type == 'Super Admin'" class="nav-item active">
            <a
              @click="all_user()"
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#modalAjoutAdmin"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi iconmenu bi-person-plus-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
                <path
                  fill-rule="evenodd"
                  d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                />
              </svg>
              Admin <span class="sr-only">(current)</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <div style="padding: 0px; margin: 0px; width: 100%" class="row">
      <div
        style="padding: 0px; margin: 0px"
        class="
          col-2
          d-none d-lg-block
          menu-vertical
          overflow-auto
          premier
          justify-content-start
        "
      >
        <nav
          style="padding: 0px; margin: 0px"
          class="navbar menu flex-column navbar-expand-lg"
        >

          <div class="circle-image mt-4 p-0">
            <img
              class="logoimage"
              src="./assets/logosite.png"
              width="75"
              height="75"
              alt=""
              srcset=""
              style="object-fit: contain"
            />
          </div>
          <hr />
          <div class="nomsite text-center">
            <div class="opac">
             Les Jeunes Solidaires
              <p>Plateforme de gestion des membres</p>
            </div>
          </div>

          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav flex-column">
              <li class="nav-item active">
                <router-link class="nav-link routlink active" to="/">
                  <span
                    ><img
                      class="iconmenu"
                      src="./assets/png/011-dashboard-1.png"
                      width="25"
                      height="25"
                      alt=""
                      srcset=""
                  /></span>
                  Tableau de bord <span class="sr-only">(current)</span>
                </router-link>
              </li>

              <li class="nav-item active">
                <router-link
                  class="nav-link routlink active"
                  :to="`/Membre-liste/${0} `"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi iconmenu bi-person-lines-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"
                    />
                  </svg>
                  Membres <span class="sr-only">(current)</span>
                </router-link>
              </li>
              <li class="nav-item active">
                <router-link
                  class="nav-link routlink active"
                  :to="`/Organisation-liste/${0} `"
                >
                  <img
                    class="iconmenu"
                    src="./assets/png/012-community-manager-1.png"
                    width="25"
                    height="25"
                    alt=""
                    srcset=""
                  />
                  Organisations <span class="sr-only">(current)</span>
                </router-link>
              </li>
              <li class="nav-item active">
                <router-link
                  class="nav-link routlink active"
                  :to="`/Evenement-liste/${0} `"
                >
                  <img
                    class="iconmenu"
                    src="./assets/png/013-calendar-silhouette-1.png"
                    width="25"
                    height="25"
                    alt=""
                    srcset=""
                  />
                  Evènements <span class="sr-only">(current)</span>
                </router-link>
              </li>
              <li class="nav-item active">
                <router-link
                  class="nav-link routlink active"
                  :to="`/Formation-liste/${0} `"
                >
                  <img
                    class="iconmenu"
                    src="./assets/png/014-note.png"
                    width="25"
                    height="25"
                    alt=""
                    srcset=""
                  />
                  Formations <span class="sr-only">(current)</span>
                </router-link>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="all_localite()"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModallocalite"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi iconmenu bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                    />
                  </svg>
                  Localités
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  @click="all_bureau()"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalbureau"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi iconmenu bi-building"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 
                                  1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 
                                  .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                    />
                    <path
                      d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 
                                  2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                    />
                  </svg>
                  Bureaux
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAdmin"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi iconmenu bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                  Profil
                </a>
              </li>

              <li v-if="user.type == 'Super Admin'" class="nav-item">
                <a
                  @click="all_user()"
                  class="nav-link"
                  data-bs-toggle="modal"
                  data-bs-target="#modalAjoutAdmin"
                  href="#"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi iconmenu bi-person-plus-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                  Admin
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div style="margin-rigth: 0px" class="deuxieme col">
        <router-view />
      </div>
    </div>
  </div>
  <div v-else>
     <div 
      style="
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        z-index: 2000;
        right: 0px;
        opacity: 1;
      "
    >
      <img
        style="width: 100%; height: 100%; object-fit: cover"
        src="./assets/login_cover04.jpg"
        alt=""
      />
    </div>
    <div 
      style="
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        z-index: 2050;
        right: 0px;
        background-color: #000;
        opacity: 0.5;
      "
    >
    </div>
  <div style="position: relative; z-index: 2100" class="login justify-content-center align-items-center">
    <div class="text-center">
      <br /><br />
      <form class="form-signin">
        <center>
          <div class="circle-image2">
            <img
              class="logoimage"
              src="./assets/logosite.png"
              width="80"
              height="80"
              style="object-fit: contain"
              alt=""
              srcset=""
            />
          </div>
        </center>
        <h5 class=" my-4 text-white font-weight-normal">Connectez-vous</h5>

        <div v-if="user_error" class="alert alert-danger" role="alert">
          Paramètre de connexion incorrect !
        </div>
        <label for="inputEmail" class="sr-only">Identifiant</label>
        <input
          v-model="identifiant"
          type="text"
          id="inputEmail"
          :class="`form-control mb-0 ${border_identifiant}`"
          placeholder="Identifiant"
          required
          autofocus
        />
        <span v-if="border_identifiant" class="validity_identifiant"
        style="position: relative; left: -70px; top: 5px"> {{etatBorder}} </span>
        <label for="inputPassword" class="sr-only">Mot de passe</label>
        <input
          v-model="mdp"
          type="password"
          id="inputPassword"
          :class="`form-control mt-3 mb-0 ${border_mdp}`"
          placeholder="Mot de passe"
          required
        />
        <span v-if="border_mdp" class="validity_mot_de_passe" 
        style="position: relative; left: -56px; top: 5px"> {{etatBorder2}} </span>
        <button
        v-if="!loadConnexion"
          @click="seConnecter()"
          class="btn btn-lg btn-primary btn-block mt-3"
          type="submit"
        >
         <span style="font-size: 15px; vertical-align: text-top;"> Se connecter</span>
        </button>
        <button
          v-if="loadConnexion"
          class="btn btn-lg btn-primary btn-block mt-3"
         
        >
          <span class="spinner-border text-white" role="status"></span>
        </button>
        <p class="mt-5 mb-3 text-muted"></p>
      </form>
      <!--div v-if="!loadConnexion" id="pdfE" ref="DownloadComp" class="mt-3">
        
              <router-link class="btn p-2" to="/connexion">Login</router-link>
            </!--div-->
      <!--VueHtml2pdf v-if="loadConnexion" :manual-pagination="true" :enable-download="true">
        <section>
            <div class="mt-3">
              <p>bla bla bla bla bla bla </p>
            
            </div>
        </section>
    </!--VueHtml2pdf-->
    
    </div>
  </div>
  </div>

  <footer class="bg-dark xfooter text-white">
    <div class="pl-xl-5">
      <div class="pl-xl-5 ml-xl-5">
      <ul class="list-inline ml-md-1 pl-md-4">
      <li class="list-inline-item"><a href="tel:+">Contact</a> |</li>
      <li class="list-inline-item">&copy; Les Jeunes Solidaires &copy;2020-2021</li>
      <li class="list-inline-item">| <a href="mailto:">Email</a></li>
    </ul>
    </div>
    </div>
  </footer>

  <!-- Modal  pour l'ajout des localitées-->
  <div
    class="modal fade"
    id="exampleModallocalite"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Localités</h5>
          <div data-bs-dismiss="modal" aria-label="Close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
        <div class="modal-body modal-ajout-participant">
          <center>
            <div class="input-group mb-3">
              <input
                v-model="Localite"
                type="text"
                class="form-control"
                placeholder="Ajouter une localité"
                aria-describedby="button-addon2"
              />
              <button
                @click="ajoutlocalite()"
                class="btn btn-outline-primary"
                type="button"
                id="button-addon2"
              >
                Ajouter
              </button>
            </div>
          </center>
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ msg }}
          </div>
          <center v-if="spiner">
            <div class="spinner-border text-primary" role="status"></div>
          </center>
          <table v-else class="table">
            <tbody>
              <tr v-for="item in all" :key="item.index">
                <th style="text-align: start" scope="row">{{ item.nom }}</th>
                <td class="tab-delete">
                  <svg
                    @click="deletelocalite(item.id)"
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 
                                  1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal  pour l'ajout des bureaux-->
  <div
    class="modal fade"
    id="exampleModalbureau"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Bureaux</h5>
          <div data-bs-dismiss="modal" aria-label="Close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
        <div class="modal-body modal-ajout-participant">
          <center>
            <div class="input-group mb-3">
              <input
                v-model="Bureau"
                type="text"
                class="form-control"
                placeholder="Ajouter un bureau"
                aria-describedby="button-addon2"
              />
              <button
                @click="ajoutbureau()"
                class="btn btn-outline-primary"
                type="button"
                id="button-addon2"
              >
                Ajouter
              </button>
            </div>
          </center>
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ msg }}
          </div>
          <center v-if="spiner">
            <div class="spinner-border text-primary" role="status"></div>
          </center>
          <table v-else class="table">
            <tbody>
              <tr v-for="item in all" :key="item.index">
                <th style="text-align: start" scope="row">{{ item.nom }}</th>
                <td class="tab-delete">
                  <svg
                    @click="deletebureau(item.id)"
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 
                                  1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            style="color: white"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal  pour le Profil Administrateur-->
  <div
    class="modal fade"
    id="modalAdmin"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Profil</h5>
          <div data-bs-dismiss="modal" aria-label="Close">
            <svg
              @click="changepwd = false"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
        <center v-if="spineri">
          <div class="spinner-border text-primary" role="status"></div>
        </center>
        <div
          v-if="changepwd == false"
          class="modal-body modal-ajout-participant"
        >
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ adminErreur }}
          </div>

          <div class="div row userprofil">
            <div class="col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="150"
                fill="#e7e7e7"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                />
              </svg>
            </div>
            <div class="col">
              <div>
                Nom:
                <div class="element">{{ user.nom }}</div>
              </div>
              <br />
              <div>
                Prenom:
                <div class="element">{{ user.prenom }}</div>
              </div>
              <br />
              <div>
                Poste:
                <div class="element">{{ user.poste }}</div>
              </div>
              <br />
              <div>
                Type de compte:
                <div class="element">{{ user.type }}</div>
              </div>
              <br />
              <div @click="changepwd = true" class="btn btn-outline-primary">
                Changer de Mot de Passe
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="changepwd == true" class="ajoutadmin modal-body">
          <center>Modifier Mot de passe</center>
          <br />
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ adminErreur }}
          </div>

          <div class="input-group mb-3">
            <input
              v-model="mdp2"
              type="password"
              class="form-control"
              placeholder="Ancien Mot de passe"
              required
              aria-describedby="button-addon2"
            />
          </div>

          <div class="input-group mb-3">
            <input
              v-model="changemdp"
              type="password"
              class="form-control"
              placeholder="Nouveau Mot de passe"
              required
              aria-describedby="button-addon2"
            />
          </div>
          <div class="input-group mb-3">
            <input
              v-model="changemdp2"
              type="password"
              class="form-control"
              placeholder="Confimer mot de passe"
              required
              aria-describedby="button-addon2"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="changepwd == true"
            @click="creer = true"
            style="color: white"
            type="button"
            class="btn btn-success"
          >
            Modifier
          </button>
          <button
            @click="
              changepwd = false;
              loG = false;
              seDeconnecter();
              user_error = false;
            "
            style="color: white"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Deconnexion
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal  pour l'ajout des Administrateurs-->
  <div
    class="modal fade"
    id="modalAjoutAdmin"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Administrateurs</h5>
          <div data-bs-dismiss="modal" aria-label="Close">
            <svg
              @click="creer = false"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 
                          8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
              />
            </svg>
          </div>
        </div>
        <center v-if="spiner">
          <div class="spinner-border text-primary" role="status"></div>
        </center>
        <div v-if="creer == false" class="modal-body modal-ajout-participant">
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ adminErreur }}
          </div>

          <table v-else class="table">
            <tbody>
              <tr v-for="item in all" :key="item.index">
                <th style="text-align: start" scope="row">
                  {{ item.nom }} {{ item.prenom }}
                </th>
                <td class="tab-delete">
                  <svg
                    @click="deleteAdmin(item.id)"
                    style="color: red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                    />
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 
                                  1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="creer == true" class="ajoutadmin modal-body">
          <center>Nouvel Administrateur</center>
          <br />
          <div
            style="text-align: center"
            v-if="erreur"
            class="alert alert-danger"
            role="alert"
          >
            {{ adminErreur }}
          </div>
          <div class="input-group mb-3">
            <input
              v-model="identifiant"
              type="text"
              class="form-control"
              placeholder="Identifiant"
              required
              aria-describedby="button-addon2"
            />
          </div>
          <div class="input-group mb-3">
            <input
              v-model="nom"
              type="text"
              class="form-control"
              placeholder="Nom"
              required
              aria-describedby="button-addon2"
            />
          </div>
          <div class="input-group mb-3">
            <input
              v-model="prenom"
              type="text"
              class="form-control"
              placeholder="Prénom"
              aria-describedby="button-addon2"
            />
          </div>
          <div class="input-group mb-3">
            <input
              v-model="poste"
              type="text"
              class="form-control"
              placeholder="Poste"
              aria-describedby="button-addon2"
            />
          </div>
          <div class="input-group mb-3">
            <select v-model="type" id="inputState" class="form-control">
              <option value="admin" selected>Admin</option>
              <option value="Super Admin">Super Admin</option>
            </select>
          </div>

          <div class="input-group mb-3">
            <input
              v-model="mdp2"
              type="password"
              class="form-control"
              placeholder="Mot de passe"
              required
              aria-describedby="button-addon2"
            />
          </div>
          <div class="input-group mb-3">
            <input
              v-model="cmdp"
              type="password"
              class="form-control"
              placeholder="Conrfimer mot de passe"
              required
              aria-describedby="button-addon2"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            v-if="creer == false"
            @click="creer = true"
            style="color: white"
            type="button"
            class="btn btn-info"
          >
            Créer
          </button>
          <button
            v-if="creer == true"
            @click="(creer = true), ajoutAdmin()"
            style="color: white"
            type="button"
            class="btn btn-success"
          >
            Créer
          </button>
          <button
            @click="creer = false"
            style="color: white"
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
input:invalid+.validity_identifiant:after {
  content: "L'identifiant est obligatoire."; 
  color: red;
}
input:invalid+.validity_mot_de_passe:after {
  content: "Le mot de passe est obligatoire"; 
  color: #ff0000;
}
/*input:invalid#inputEmail, input:invalid#inputPassword {
  border-color: red;
}*/
</style>
<script>
//const { required, minLength, maxLength } = require('vuelidate/lib/validators')
//import { jsPDF } from "jspdf";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
//import VueHtml2pdf from 'vue-html2pdf';
import axios from "axios";
export default {
  name: "app",
  //components: { VueHtml2pdf },
  data: () => {
    return {
      ajouteur: "",
      all: "",
      spiner: false,
      Localite: "",
      Bureau: "",
      loG: false,
      msg: "",
      erreur: false,
      identifiant: "",
      mdp: "",
      reponxCode: "",
      superadmin: "",
      type: "admin",
      nom: "",
      prenom: "",
      poste: "",
      droits: "",
      cmdp: "",
      mdp2: "",
      lecture_droit: "0",
      modification_droit: "0",
      suppression_droit: "0",
      creer: false,
      adminErreur: "",
      changepwd: false,
      changemdp: "",
      changemdp2: "",
      user: "",
      user_error: false,
      loadConnexion: false,
      border_identifiant: "",
      border_mdp: "",
    };
  },
  computed: {
    etatBorder(){
      return this.etat();
    },
    etatBorder2(){
      return this.etat2();
    }
  },
  methods: {
    etat(){
      if (!(this.identifiant=="")) {
       return this.border_identifiant="";
      }
    },
    etat2(){
      if (!(this.mdp=="")) {
       return this.border_mdp = "";
      }
    },
    all_bureau: async function () {
      this.spiner = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_bureaux.php")
        .then((response) => {
          this.all = response.data.values;
          console.log(this.all);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spiner = false;
        });
    },
    all_localite: async function () {
      this.spiner = true;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_localites.php")
        .then((response) => {
          this.all = response.data.values;
          console.log(this.all);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spiner = false;
        });
    },
    all_user: async function () {
      this.spiner = true;
      this.erreur = false;
      axios
        .get("https://www.rab-tech.com/dev_mode/js/api/get_all_users.php")
        .then((response) => {
          this.all = response.data.values;
          console.log(this.all);
        })
        .catch((error) => {
          console.log(error);
          this.erreur = true;
          this.spiner = false;
          this.adminErreur = "Probleme de connexion";
        })
        .finally(() => {
          this.spiner = false;
        });
    },
    ajoutlocalite() {
      const AJOUT = new FormData();
      this.erreur = false;
      AJOUT.set("nom", this.Localite);
      this.spiner = true;
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/save_localite.php",
          AJOUT
        )
        .then((response) => {
          console.log(response);
          this.reponxCode = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.erreur = true;
          this.msg = "Problème de connexion. Veillez réessayer svp !";
        })
        .finally(() => {
          if (this.reponxCode == "201") {
            this.Localite = "";
            this.all_localite();
          } else {
            this.spiner = false;
            this.erreur = true;
            this.Localite = "";
            this.msg = "Une est survenue . Veillez réessayer svp !";
          }
        });
    },
    createHeaders(keys) {
        return keys.map(key => ({
          'name': key,
          'prompt': key,
          'width':65,
          'align':'center',
          'padding':0
        }));
    },
    generateReport() {
      console.log("oui");
      const doc = new jsPDF();
      doc.autoTable({
      head: [['Name', 'Email', 'Country']],
      body: [
        ['David', 'david@example.com', 'Sweden'],
        ['Castille', 'castille@example.com', 'Spain'],
        // ...
      ],
    });

    doc.save('table.pdf');
      /*const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [4, 2]
      });
      
      let data = [{
          coin: "100",
          game_group: "GameGroup",
          game_name: "XPTO1",
          game_version: "25",
          machine: "20485861",
          vlt: "0"
        },
        {
          coin: "100",
          game_group: "GameGroup",
          game_name: "XPTO2",
          game_version: "25",
          machine: "20485861",
          vlt: "0"
        }];
      var header = this.createHeaders(["coin", "game_group", "game_name", "game_version", "machine", "vlt"]);
      pdf.table(1, 1, data, header)
      pdf.save("pdf.pdf");
      pdf.text("Hello world!", 10, 10);
      pdf.save("a4.pdf");*/
           // this.$refs.html2Pdf.generatePdf()
       
       /*let w = window.open()
          w.document.write(document.querySelector("#pdfE").innerHTML)
          w.document.close()
          w.setTimeout(function () {
            w.print()
          }, 1000)*/
      },
   seConnecter() {
    if (!this.identifiant || !this.mdp) {
      if (!this.identifiant) {
      this.border_identifiant= "border-1 border-danger";
    }
      if (!this.mdp) {
        this.border_mdp = "border-1 border-danger";
      }
    }
      else {
        if (this.identifiant && this.mdp) {
       this.loadConnexion=true;
      this.loG = false;
      const CON = new FormData();
              this.erreur=false
               CON.set('identifiant',this.identifiant )
               CON.set('mp',this.mdp )
               this.spiner=true;
                axios.post ('https://www.rab-tech.com/dev_mode/js/api/connect_user.php',CON)
                .then((response) =>{
                console.log(response.data)
                this.user=response.data.user
                if (this.user) {
                    sessionStorage.setItem("authUser", JSON.stringify(this.user));
                } else {
                    sessionStorage.removeItem('authUser');
                }
                this.reponxCode=response.data.code
               } )
               .catch((error)=> {
                 this.loadConnexion=false;
                 console.log(error);
               } )
               .finally(()=> {
                 this.loadConnexion=false;
                 if(this.reponxCode=='200')
                 { 
                   this.loG=true; 
                 }else 
                 { 
                   this.user_error=true
                   //alert('Paramètre de connexion incorrect')
                 }

                
               } )
    }
      }
    },
    ajoutbureau() {
      const AJOUT = new FormData();
      this.erreur = false;
      AJOUT.set("nom", this.Bureau);
      this.spiner = true;
      axios
        .post("https://www.rab-tech.com/dev_mode/js/api/save_bureau.php", AJOUT)
        .then((response) => {
          console.log(response);
          this.reponxCode = response.data.code;
        })
        .catch((error) => {
          console.log(error);
          this.erreur = true;
          this.msg = "Problème de connexion. Veillez réessayer svp !";
        })
        .finally(() => {
          if (this.reponxCode == "201") {
            this.Bureau = "";
            this.all_bureau();
          } else {
            this.spiner = false;
            this.erreur = true;
            this.Bureau = "";
            this.msg = "Une est survenue . Veillez réessayer svp !";
          }
        });
    },
    deletelocalite(info_id) {
      this.ajouteur = info_id;
      this.erreur = false;
      this.spiner = true;
      const DEL = new FormData();
      DEL.set("id", this.ajouteur);
      axios
        .post(
          "https://www.rab-tech.com/dev_mode/js/api/delete_localite.php",
          DEL
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de la supression. Veillez réessayer svp !";
          this.erreur = true;
          this.spiner = false;
        })
        .finally(() => {
          this.all_localite();
        });
    },
    deletebureau(info_id) {
      this.spiner = true;
      this.ajouteur = info_id;
      this.erreur = false;
      const DEL = new FormData();
      DEL.set("id", this.ajouteur);
      axios
        .post("https://www.rab-tech.com/dev_mode/js/api/delete_bureau.php", DEL)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.msg = "Echec de la supression. Veillez réessayer svp !";
          this.erreur = true;
          this.spiner = false;
        })
        .finally(() => {
          this.all_bureau();
        });
    },
    ajoutAdmin() {
      const AJOUT = new FormData();
      this.erreur = false;
      AJOUT.set("identifiant", this.identifiant);
      AJOUT.set("nom", this.nom);
      AJOUT.set("prenom", this.prenom);
      AJOUT.set("poste", this.poste);
      AJOUT.set("mp", this.mdp2);
      AJOUT.set("type", this.type);
      if (this.mdp2 !== this.cmdp) {
        this.spiner = false;
        this.erreur = true;
        this.adminErreur = "Mot de passe incorrect";
      } else {
        this.spiner = true;
        axios
          .post("https://www.rab-tech.com/dev_mode/js/api/save_user.php", AJOUT)
          .then((response) => {
            console.log(response);
            this.reponxCode = response.data.code;
          })
          .catch((error) => {
            console.log(error);
            this.erreur = true;
            this.msg = "Problème de connexion. Veillez réessayer svp !";
          })
          .finally(() => {
            if (this.reponxCode == "201") {
              this.identifiant = "";
              this.lecture_droit = "";
              this.modification_droit = "";
              this.suppression_droit = "";
              this.nom = "";
              this.prenom = "";
              this.mdp2 = "";
              this.cmdp = "";
              this.poste = "";
              this.creer = false;
              this.all_user();
            } else {
              this.spiner = false;
              this.erreur = true;
              this.adminErreur = "Une est survenue . Veillez réessayer svp !";
            }
          });
      }
    },
    deleteAdmin(info_id) {
      this.ajouteur = info_id;
      this.erreur = false;
      this.spiner = true;
      const DEL = new FormData();
      DEL.set("id", this.ajouteur);
      axios
        .post("https://www.rab-tech.com/dev_mode/js/api/delete_user.php", DEL)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          this.adminErreur = "Echec de la supression. Veillez réessayer svp !";
          this.erreur = true;
          this.spiner = false;
        })
        .finally(() => {
          this.all_user();
        });
    },
    seDeconnecter(){
      sessionStorage.removeItem('authUser');
    }
  },
  created() {
    let verifierUser = JSON.parse(sessionStorage.getItem("authUser"))
      if (verifierUser) {
        this.user=verifierUser;
        this.loG=true;
      }else{
        this.loG=false;
      }
  },
};
</script>
