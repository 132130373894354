<template>
  <div>
    <section class="boxAcceuil content-center container-lg box-center">
      <!--div class="bluehead">
        <div class="row">
          <div style="text-align: center" class="col"><h5>Acceuil</h5></div>
        </div>
      </!--div-->

      <center v-if="echec">
        <div>Probleme de connexion.Reéssayer svp</div>
        <svg
          @click="recharger()"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          style="color: blue; cursor: pointer"
          fill="currentColor"
          class="bi bi-bootstrap-reboot"
          viewBox="0 0 16 16"
        >
          <path
            d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8tableau"
          />
          <path
            d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141tableaum0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6tableau"
          />
        </svg>
      </center>
      <center class="milieu mt-3" v-if="spiner">
        <div class="spinner-border text-primary" role="status"></div>
      </center>

      <div v-else-if="echec == false" class="masque row mt-3">
        <div style="margin: 0px" class="col-md-4 order-2">
          <div class="shadow-sm border">
            <legend class="xxx">Proportion Membre/Profession</legend>
            <apexchart
              class="apex"
              type="pie"
              :options="optionsprofession"
              :series="seriesprofession"
            ></apexchart>
          </div>

          <div class="shadow-sm border">
            <legend class="xxx">Proportion Membre/Zone</legend>
            <apexchart
              class="apex"
              type="pie"
              :options="optionszone"
              :series="serieszone"
            ></apexchart>
          </div>

          <div class="shadow-sm border">
            <legend class="xxx">Proportion Membre/Genre</legend>
            <apexchart
              class="apex"
              type="pie"
              :options="chartOptions"
              :series="chartseries"
            ></apexchart>
          </div>

          <div class="shadow-sm border">
            <legend class="xxx">Proportion Membre/Cotisation</legend>
            <apexchart
              class="apex"
              type="pie"
              :options="optionsd"
              :series="seriesd"
            ></apexchart>
          </div>
        </div>
        <div class="col">
          <!-- SECTION POUR LES CARTES DE STATS-->
          <center class="boxsection row row-cols-2 text-light">
            <div class="carte col row border shadow bg-primary mt-0">
              <div class="col part-un">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-people"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                  />
                </svg>
                <div class="carte-titre">Membres directs (PP & PM)</div>
                <div class="bold">
                  <h3>{{ reponxStat.nbr_membres_directs }}</h3>
                </div>
              </div>
              <div class="col text-primary align-self-center py-3 part-2">
                Hommes {{ reponxStat.nbr_hommes_directs }}
                <hr class="trait" />
                Femmes {{ reponxStat.nbr_femmes_directs }}
                <hr class="trait" />
                Organisations {{ reponxStat.nbr_organisations }}
              </div>
            </div>
            <div class="carte col row border shadow bg-warning mt-0">
              <div class="col part-un">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-people"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                  />
                </svg>
                <div class="carte-titre">Membres indirects</div>
                <div class="bold">
                  <h3>{{ reponxStat.nbr_membres_indirects }}</h3>
                </div>
              </div>
              <div class="col align-sel-center text-warning py-4 part-2">
                Hommes {{ reponxStat.nbr_hommes_indirects }}
                <hr class="trait" />
                Femmes {{ reponxStat.nbr_femmes_indirects }}
                <hr class="trait" />
              </div>
            </div>

            <div class="carte col row border shadow bg-dark">
              <div class="col part-un">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-people"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
                  />
                </svg>
                <div class="carte-titre">
                  Effectif Total (Direct & Indirect)
                </div>
                <div class="bold">
                  <h3>{{ reponxStat.nbr_membres }}</h3>
                </div>
              </div>
              <div class="col align-self-center text-dark py-3 part-2">
                Hommes {{ reponxStat.nbr_hommes }}
                <hr class="trait" />
                Femmes {{ reponxStat.nbr_femmes }}
                <hr class="trait" />
                Organisations {{ reponxStat.nbr_organisations }}
              </div>
            </div>

            <div class="carte col row border shadow bg-danger">
              <div class="col part-un">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-wallet2"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
                  />
                </svg>
                <div class="carte-titre">Etat de cotisation</div>
                <div class="bold">
                  <h3>{{ reponxStat.nbr_membres }}</h3>
                </div>
              </div>
              <div class="col align-self-center text-danger py-3 part-2">
                A jour {{ reponxStat.nbr_membres_a_jour }}
                <hr class="trait" />
                Non à jour {{ reponxStat.nbr_membres_non_a_jour }}
                <hr class="trait" />
                En cours {{ reponxStat.nbr_membres_en_cours_a_jour }}
              </div>
            </div>
          </center>

          <!-- SECTION POUR LES TABLEAUX STATS -->
          <section class="section-table">
            <div class="table-stats">
              <div class="filtre">
                <div class="search">
                  <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Filtre</div>
                    </div>
                    <select
                      @click="ecoutefiltre()"
                      v-model="filtre"
                      placeholder="Filtré par.."
                      class="custom-select"
                      id="validationDefault04"
                      required
                    >
                      <option
                        v-for="fil in reponxStat.fitre_tableau_data"
                        :key="fil.index"
                        :value="fil"
                      >
                        {{ fil }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <table class="table table-striped table-bordered">
                <thead class="bg-info text-white">
                  <tr>
                    <th v-for="item in tableau" :key="item.index" scope="col">
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ligne in tablContenu" :key="ligne.index">
                    <td v-for="item in ligne" :key="item.index">{{ item }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            <center>
              <div class="col">
                <apexchart
                  class="apexL"
                  type="bar"
                  :options="options"
                  :series="series"
                ></apexchart>
              </div>

              <!--div class="col">
                <legend>Graphe 2</legend>
                <apexchart
                  class="apexl"
                  type="line"
                  :options="options"
                  :series="series"
                ></apexchart>
              </!--div-->
            </center>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
//import RadialBarGraph from 'vue-radial-bargraph';
import apexchart from "vue3-apexcharts";

export default {
  name: "Acceuil",
  components: {
    apexchart,
  },
  data: () => {
    return {
      sections: [{ value: 25 }, { value: 25 }],
      reponx: [],
      spiner: true,
      echec: false,
      tableau: [],
      i: 0,
      ii: 0,
      tablLigne: [],
      tablContenu: [],
      reponxStat: [],
      jsonreponx: [],
      tableau2: [],
      filtre: "",
      aff: false,
      x: false,
      chartdata: [],
      nombre: 0,
      options: {
        chart: {
          id: "graphe",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "membre(s)",
          data: [],
        },
      ],
      seriesd: [],
      optionsd: {
        legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'right',
      horizontalAlign: 'center', 
      floating: false,
      fontSize: '8px',
      fontFamily: 'Poppins, Helvetica, Arial',
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
          colors: undefined,
          useSeriesColors: false
      },
      markers: {
          width: 9,
          height: 9,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
      },
      itemMargin: {
          horizontal: 2,
          vertical: 0
      },
      onItemClick: {
          toggleDataSeries: true
      },
      onItemHover: {
          highlightDataSeries: true
      },
  },
        labels: ["A jour", "Non à jour", "En cours"],
        colors: [
          "rgb(0, 143, 251)",
          "rgb(0,143,96)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
          "rgb(75, 0, 151)",
          "rgb(176, 88, 0)",
          "rgb(74, 74, 74)",
        ],
      },
      chartseries: [],
      chartOptions: {
        legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'right',
      horizontalAlign: 'center', 
      floating: false,
      fontSize: '8px',
      fontFamily: 'Poppins, Helvetica, Arial',
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
          colors: undefined,
          useSeriesColors: false
      },
      markers: {
          width: 9,
          height: 9,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
      },
      itemMargin: {
          horizontal: 2,
          vertical: 0
      },
      onItemClick: {
          toggleDataSeries: true
      },
      onItemHover: {
          highlightDataSeries: true
      },
  },
        labels: ["Femmes", "Hommes"],
        colors: [
          "rgb(0, 143, 251)",
          "rgb(0,143,96)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
          "rgb(75, 0, 151)",
          "rgb(176, 88, 0)",
          "rgb(74, 74, 74)",
        ],
      },
      serieszone: [],
      optionszone: {
        legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'right',
      horizontalAlign: 'center', 
      floating: false,
      fontSize: '8px',
      fontFamily: 'Poppins, Helvetica, Arial',
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
          colors: undefined,
          useSeriesColors: false
      },
      markers: {
          width: 9,
          height: 9,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
      },
      itemMargin: {
          horizontal: 2,
          vertical: 0
      },
      onItemClick: {
          toggleDataSeries: true
      },
      onItemHover: {
          highlightDataSeries: true
      },
  },
        labels: [],
        colors: [
          "rgb(0, 143, 251)",
          "rgb(0,143,96)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
          "rgb(75, 0, 151)",
          "rgb(176, 88, 0)",
          "rgb(74, 74, 74)",
        ],
      },
      seriesprofession: [],
      optionsprofession: {
        legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'right',
      horizontalAlign: 'center', 
      floating: false,
      fontSize: '8px',
      fontFamily: 'Poppins, Helvetica, Arial',
      fontWeight: 500,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
          colors: undefined,
          useSeriesColors: false
      },
      markers: {
          width: 9,
          height: 9,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
      },
      itemMargin: {
          horizontal: 2,
          vertical: 0
      },
      onItemClick: {
          toggleDataSeries: true
      },
      onItemHover: {
          highlightDataSeries: true
      },
  },
        labels: [],
        colors: [
          "rgb(0, 143, 251)",
          "rgb(0,143,96)",
          "rgb(254, 176, 25)",
          "rgb(255, 69, 96)",
          "rgb(75, 0, 151)",
          "rgb(176, 88, 0)",
          "rgb(74, 74, 74)",
        ],
      },
    };
  },
  methods: {
    addition(value1, value2) {
      return value1 + value2;
    },
    lanceRequette: async function () {
      this.spiner = true;
      this.echec = false;
      (this.seriesd = []),
        (this.chartseries = []),
        (this.serieszone = []),
        (this.seriesprofession = []);
      (this.optionsprofession.labels = []), (this.optionszone.labels = []);
      (this.series[0].data = []), (this.options.xaxis.categories = []);
      axios
        .get(
          "https://www.rab-tech.com/dev_mode/js/api/get_stats_data.php?filtre=" +
            this.filtre
        )
        .then((response) => {
          this.reponxStat = response.data;
          //repartion par cotisation
          this.chartdata = response.data;

          while (this.nombre != this.reponxStat.nbr_membres) {
            this.nombre++;
            if (this.nombre == this.reponxStat.nbr_membres_a_jour) {
              this.seriesd.push(this.nombre);
              this.nombre = 0;
              break;
            }
          }
          while (this.nombre != this.reponxStat.nbr_membres) {
            this.nombre++;
            if (this.nombre == this.reponxStat.nbr_membres_non_a_jour) {
              this.seriesd.push(this.nombre);
              this.nombre = 0;
              break;
            }
          }
          while (this.nombre != this.reponxStat.nbr_membres) {
            this.nombre++;
            if (this.nombre == this.reponxStat.nbr_membres_en_cours_a_jour) {
              this.seriesd.push(this.nombre);
              this.nombre = 0;
              break;
            }
          }

          //repartion par genre
          this.chartdata.repartitions_genre.forEach((element) => {
            while (this.nombre != this.reponxStat.nbr_membres) {
              this.nombre++;
              if (this.nombre == element.valeur) {
                this.chartseries.push(this.nombre);
                this.nombre = 0;
                break;
              }
            }
          });

          //repartion par zone
          this.chartdata.repartitions_zone.forEach((element) => {
            while (this.nombre != this.reponxStat.nbr_membres) {
              this.nombre++;
              if (this.nombre == element.valeur) {
                this.optionszone.labels.push(element.zone);
                this.serieszone.push(this.nombre);
                this.nombre = 0;
                break;
              }
            }
          });

          //repartion par profession
          this.chartdata.repartitions_profession.forEach((element) => {
            while (this.nombre != this.reponxStat.nbr_membres) {
              this.nombre++;
              if (this.nombre == element.valeur) {
                this.optionsprofession.labels.push(element.profession);
                this.seriesprofession.push(this.nombre);
                this.nombre = 0;
                break;
              }
            }
          });

          //alert(this.seriesd)

          //creation du contenu du tableau
          this.tablContenu = [];
          response.data.tableau_data.forEach((element) => {
            this.jsonreponx = JSON.stringify(element);
            this.tableau2 = this.jsonreponx.split('","');
            this.ii = 0;
            this.tablLigne = [];
            this.tableau2.forEach((val) => {
              val = val.split('":"')[1].replace('"}', "");
              this.tablLigne.push(val);

              this.ii = this.ii + 1;
            });

            this.tablContenu.push(this.tablLigne);
            //alert(this.tablLigne)
            this.series[0].data.push(this.tablLigne[1]);
            this.options.xaxis.categories.push(this.tablLigne[0]);
            this.tableau2 = this.jsonreponx.split(",");
          });

          //creation du tableau d'entete
          this.reponx = JSON.stringify(response.data.tableau_data[0]);
          this.tableau = this.reponx.split('","');
          /*
                this.reponx=this.reponx.replace('{','');
                this.reponx=this.reponx.replace('}','');
                this.reponx=this.reponx.replaceAll('"','');*/

          this.i = 0;
          this.tableau.forEach((val) => {
            val = val.split('":"')[0].replace('{"', "").replace("_", " ").toUpperCase();
            this.tableau[this.i] = val;
            this.i = this.i + 1;

            //this.filtre=this.reponxStat.fitre_tableau_data[0]
          });
        })
        .catch((error) => {
          console.log(error);
          this.echec = true;
          this.spiner = false;
        })
        .finally(() => {
          this.spiner = false;
          console.log(this.tablLigne);
          console.log(this.tableau);
          console.log(this.tablContenu);
        });
    },
    recharger() {
      //window.location.reload();
      if (this.filtre !== "") {
        this.lanceRequette();
      }
    },
  },
  created() {
    this.lanceRequette();
  },
  computed: {
    ecoutefiltre() {
      return this.recharger(() => {
        if (this.filtre !== "") {
          this.recharger();
        }
      });
    },
  },
};
</script>