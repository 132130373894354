import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/connexion',
    name: 'connexion',
    component: () => import('../views/Connexion.vue')
  },
  /*{
    path: '/vcf',
    name: 'vcf',
  },*/
  ////////PROFIL////////
  {
    path: '/profil/',
    name: 'Profil',
    component: () => import('../views/Profil.vue')
  },
  ////////ACCEUIL////////
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  ////////ORGANISATION////////
  {
    path: '/organisations/:id',
    name: 'Organisations',
    component: () => import('../views/Organisations.vue')
  },
  {
    path: '/Organisation-liste/:id',
    name: 'Organisationliste',
    component: () => import('../views/Liste_o.vue')
  },
  {
    path: '/Organisation-detail/:id',
    name: 'Organisationdetail',
    component: () => import('../views/Detail_o.vue')
  },
  ////////MEMBRE////////
  {
    path: '/membres/:id',
    name: 'Membres',
    component: () => import('../views/Membres.vue')
  },
  {
    path: '/membre-liste/:id',
    name: 'liste_membre',
    component: () => import('../views/Liste_m.vue')
  },
  {
    path: '/membre-detail/:id',
    name: 'detail_membre',
    component: () => import('../views/Detail_m.vue')
  },
  ////////FORMATION////////
  {
    path: '/formations/:id',
    name: 'Formations',
    component: () => import('../views/Formations.vue')
  },{
    path: '/Formation-liste/:id',
    name: 'Formationliste',
    component: () => import('../views/Liste_f.vue')
  },
  {
    path: '/Formation-detail/:id',
    name: 'Formationdetail',
    component: () => import('../views/Detail_f.vue')
  },
  ////////EVENEMENT////////
  {
    path: '/evenements/:id',
    name: 'Evenements',
    component: () => import('../views/Evenements.vue')
  },
  {
    path: '/Evenement-liste/:id',
    name: 'Evenementliste',
    component: () => import('../views/Liste_e.vue')
  },
  {
    path: '/Evenement-detail/:id',
    name: 'Evenementdetail',
    component: () => import('../views/Detail_event.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
